<template>
  <div class="order-containter">
    <nav-menu></nav-menu>
    <div class="order-body">
      <div class="containter">
        <div class="order-title">我的订单</div>
        <div class="order-tabs flex-a">
          <div :class="tab == item.status ? 'order-tab active' : 'order-tab'" v-for="item in tabs" :key="item.id"
            @click="changeTab(item.status)">{{ item.title }}</div>
        </div>
        <div class="order-search flex-a">
          <el-input v-model="keyword" placeholder="请输入搜索关键字" @keyup.enter.native="search"></el-input>
          <div class="btn" @click="search">查询</div>
        </div>
        <div class="order-list">
          <div class="order-item" v-for="(item, index) in list" :key="index">
            <div class="order-item-title flex-a flex-b">
              <div class="order-item-title-tag">
                <span>{{ item.tendName }}</span>
                <el-tag size="small"
                  :type="item.status == '0' ? 'primary' : item.status == 'warning' ? '' : item.status == '2' ? 'info' : item.status == '3' ? 'success' : ''">
                  {{ item.status == '0' ? '申请中' : item.status == '1' ? '已申请' : item.status == '2' ? '已取消' : item.status
                      ==
                      '3' ? '已完成' : ''
                  }}</el-tag>
                <el-tag size="small" type="danger">
                  {{ item.type == '2' ? '退保中' : item.type == '3' ? '理赔中' : '' }}</el-tag>
              </div>
              <div class="order-item-title-btns">
                <!-- <span class="btn-plain">评价</span> -->
                <span class="btn-plain" @click="toDetail(item.orderNo)">查看</span>
                <span class="btn-plain" v-if="item.type != '2'" @click="surrender('2', item.orderNo)">退保</span>
                <span class="btn-plain" v-if="item.type != '2'" @click="surrender('3', item.orderNo)">理赔</span>
                <span class="btn-plain">申请发票</span>
              </div>
            </div>
            <div class="order-item-body">
              <div>标段编号：{{ item.tendNo }}</div>
              <div>保函编号：{{ item.gatNo }}</div>
              <div>保证金金额：{{ item.prjEarMoney }}</div>
              <div>申请时间：{{ item.createdAt }}</div>
              <div>开标时间：{{ item.tendStDate }}</div>
            </div>
            <!-- <div class="order-item-btn btn-plain" @click="toDetail">查看</div> -->
          </div>
          <div class="order-page" v-if="list.length>0">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getOrderList, postCkType } from '@/lib/api/order'
export default {
  name: 'order',
  components: {},
  data() {
    return {
      tab: '',
      total: 0,
      keyword: '',
      tabs: [
        {
          status: '',
          title: '全部'
        },
        {
          status: '0',
          title: '申请中'
        },
        {
          status: '1',
          title: '已申请'
        },
        {
          status: '2',
          title: '已取消'
        }
      ],
      list: []
    }
  },
  created() {
    this.getList({ orderNo: 'null', status: this.tab, pageSize: 10, pageNum: 1 ,name:this.keyword})//获取订单列表
  },
  methods: {
    surrender(type, orderNo) {
      postCkType({ type, orderNo }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    //查询
    search(){
      this.getList({ orderNo: 'null', status: this.tab, pageSize: 10, pageNum: 1 ,name:this.keyword})//获取订单列表
    },
    //获取订单列表
    getList(params) {
      getOrderList(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        } else {
          this.$alert('请先登录后再查看!', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.$router.push('/')
            }
          });
        }
      })
    },
    //tab切换
    changeTab(index) {
      this.tab = index
      this.getList({ orderNo: 'null', status: this.tab, pageSize: 10, pageNum: 1,name:this.keyword })//获取订单列表
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getList({ orderNo: 'null', status: this.tab, pageSize: 10, pageNum: val,name:this.keyword})//获取订单列表
    },
    //查看详情
    toDetail(orderNo) {
      this.$router.push({ path: '/orderDetail', query: { orderNo: orderNo } })
    }
  }
}
</script>
<style scoped lang="less">
.order-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .order-body {
    padding: 20px 0;

    .containter {
      .order-title {
        color: #1890FF;
        font-weight: bold;
        font-size: 20px;
        padding: 10px 0 30px 40px;
      }

      .order-tabs {
        padding-left: 40px;

        .order-tab {
          border: 1px solid #D9D9D9;
          border-radius: 5px;
          margin-right: 10px;
          color: #5A5A5A;
          padding: 5px 12px;
          font-size: 14px;
        }

        .order-tab.active {
          color: #fff;
          background: #1890FF;
          border-color: #1890FF;
        }

        .order-tab:hover {
          cursor: pointer;
        }
      }
    }

    .order-search {
      margin: 20px auto;
      width: 70%;

      .btn {
        margin-left: 20px;
        letter-spacing: 3px;
      }
    }

    .order-list {
      padding: 0 10px;

      .order-item {
        border-radius: 5px;
        padding: 20px 60px 10px 50px;
        box-shadow: 0 0 10px #E6E6E6;
        margin-bottom: 15px;
        position: relative;

        .order-item-title {
          color: #000;
          font-size: 18px;
          margin-bottom: 15px;

          .order-item-title-tag {
            span {
              margin-right: 10px;
            }
          }

          .order-item-title-btns {
            span {
              border-color: #157EDF;
              color: #1890FF;
              padding: 3px 8px;
              border-radius: 10px;
              margin-left: 15px;
            }
          }
        }

        .order-item-body {
          div {
            display: inline-block;
            width: 45%;
            margin-bottom: 10px;
            font-size: 14px;
            color: #5A5A5A;
          }
        }

        .order-item-btn {
          border-color: #157EDF;
          color: #1890FF;
          width: 60px;
          position: absolute;
          top: 50%;
          right: 60px;
          transform: translateY(-50%);
        }
      }

      .order-page {
        text-align: center;
      }
    }
  }
}
</style>
